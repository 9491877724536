import React, { useEffect } from 'react';
import '../css/App.css';
import {serverCalculation} from '../functions/MCServerCalc';
import { Link,useLocation } from 'react-router-dom';
import { Select, Stack, HStack, Divider, Card, 
CardBody, CardHeader,CardFooter, Text, Container, Button,
NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
Badge, Stat, StatGroup, StatLabel, StatNumber, StatHelpText, Center, Tooltip, 
Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
useDisclosure} from '@chakra-ui/react'


function Index() {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure()

  //If the state is undefined or null, set state.type to "Vanilla" and state.mods to 0
  if (location.state === undefined || location.state === null) {
    location.state = {type: "Vanilla", mods: 0, players: 2};
  }
  
  const [players, setPlayers] = React.useState(location.state.players);
  const changePlayers = (valueAsString: string, valueAsNumber: number) => {
    setPlayers(valueAsNumber);
}

  const [mods, setMods] = React.useState(location.state.mods);
  const changeMods = (valueAsString: string, valueAsNumber: number) => {
      setMods(valueAsNumber);
  }
  

  //Make sure the mods value is never below 0 or null or undefined
  if (mods < 0 || mods === null || mods === undefined) {
    setMods(0);
  }

  const [os, setOs] = React.useState("linux");
  const changeOs = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setOs(event.target.value);
    const total = serverCalculation(players, mods, os);
    setTotalValue(total);
  }
  
  //Make the total value available to the rest of the app
  const [totalValue, setTotalValue] = React.useState(serverCalculation(players, mods, os));

  //bool to check if the server is running Forge, Fabric or Spigot

  let hide = true;
  let bad = "red";
  let opac = 1;

  //Check if the server is running Forge, Fabric or Spigot and enable the mods input
  const [server, setServer] = React.useState(location.state.type.toString());
  const checkServer = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setServer(event.target.value);
    if (event.target.value === "Forge" || event.target.value === "Fabric" || event.target.value === "Spigot") {
      setServer(event.target.value);
    } else {
      setMods(0);
    }
  }

  //Default value of mods should be 1 if the server is running Forge, Fabric or Spigot

  if (server === "Forge" || server === "Fabric" || server === "Spigot") {
    if (mods === 0) {
      setMods(1);
    }
  }

  //Open a external link with button
  const openLink = () => {
    window.open("https://www.buymeacoffee.com/EirikBjor", '_blank');
  }

  useEffect(() => {
    setTotalValue(serverCalculation(players, mods, os))
  }, [players, mods, os])

  //Make sure hide is always true when the server is Vanilla
  if (server === "Vanilla") {
    hide = true;
    bad = "blackAlpha";
    opac = 0.4;
  } else {
    hide = false;
    bad = "green";
    opac = 1;
  }

  return (
    <div className="App">
      <header className="App-header">
        <Card backgroundColor="gray.200" className='fullscreen'>
          <CardHeader>Minecraft Server Calculator</CardHeader>
          <CardBody>
            <Text> Put in your details for a server running</Text>
              <Container maxW="container.xl">
                <HStack spacing={4}>
                <Select variant='outline' onChange={checkServer} value={server}>
                  <option id='Vanilla'>Vanilla</option>
                  <option id="Forge">Forge</option>
                  <option id="Fabric">Fabric</option>
                  <option id="Spigot">Spigot</option>
                </Select>
                  <Select variant='outline' onChange={changeOs} value={os}>
                    <option id='linux'>Linux</option>
                    <option id="windows">Windows</option>
                  </Select>
                </HStack>
                <Stack spacing={2}>
                <Divider orientation='vertical' />
                <Badge ml='1' fontSize='0.8em' colorScheme='green'>Players</Badge>
                <NumberInput min={2} max={100} value={players} onChange={changePlayers}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
                <Divider orientation='vertical' />
                </Stack>
                <Stack spacing={2}>
                  <Badge ml='1' fontSize='0.8em' colorScheme={bad} opacity={opac}>Mods</Badge>
                  <NumberInput isDisabled={hide} min={0} max={300} value={mods} onChange={changeMods}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
                <Divider orientation='vertical' />
                </Stack>
                <Tooltip label="Will always recommend a little more" placement="bottom">
                <Text fontSize='xl' fontWeight='bold'>Recommended specs</Text>
                </Tooltip>
                <Center height='30px'>
                  <Divider/>
                </Center>
                <Stack spacing={4}>
                    <StatGroup>
                      <Stat>
                        <StatLabel>CPU</StatLabel>
                        <StatNumber>{totalValue.cpu_needed}</StatNumber>
                        <StatHelpText>GHz</StatHelpText>
                      </Stat>
                      <Stat>
                        <StatLabel>RAM</StatLabel>
                        <StatNumber>{totalValue.ram_needed}</StatNumber>
                        <StatHelpText>GB</StatHelpText>
                      </Stat>
                    </StatGroup>
                </Stack>
              </Container>
          </CardBody>
          <CardFooter>
          <Stack spacing={4} width="100%">
          <Link to ={"/tutorial"} state={{type: server, mods: mods, ram: totalValue.ram_needed, players: players}}>
            <Button size='lg' colorScheme="yellow">
              Build you own dedicated server
            </Button>
          </Link>
          <Link to ={"/providers"} state={{type: server, mods: mods, ram: totalValue.ram_needed, players: players, cpu: totalValue.cpu_needed}}>
          <Button size="lg" colorScheme="red">
            Hosting Providers
          </Button>
          </Link>
          <HStack direction='row' spacing={2} width="100%">
            <Button size="lg" colorScheme="blue" onClick={onOpen}>
              Info
            </Button>
            <Button size="lg" colorScheme="green" onClick={openLink}>
            Buy me a coffee!
            </Button>
          </HStack>  
          </Stack>
          </CardFooter>
        </Card>
        
          <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Info about calculations</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>
                It takes into account the number of players that will be playing the game and the number of additional modifications (mods) that will be used, as well as the performance of the operating system. It then calculates the total amount of resources needed to run the game smoothly.
                It starts by calculating the amount of RAM needed, taking the recommended amount of RAM and then adding a certain percentage based on the number of players and mods. The same calculation is done for CPU and storage. Then it adds all three parts (RAM, CPU, storage) to get the total amount of resources needed to run the game.
                This formula helps to ensure that the computer has enough resources to run the game smoothly, avoiding lag or other performance issues.
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
      </header>
    </div>
  );
}

export default Index;