import {startMaker } from "./ShellMaker";

export async function downloadStart(ram: number, type: string) {
    const start = startMaker(ram, type);

    let fileContent = start;
    let fileName = "start.sh";

    //Create a Blob of the content
    let blob = new Blob([fileContent], { type: "text/plain" });

    //Create a link element, hide it, direct it towards the blob, and use it to click
    let a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    //Create a DOMString representing the blob and point the link element towards it
    let url = window.URL.createObjectURL(blob);
    a.href = url;

    //Set the file name of the download link 
    a.download = fileName;

    //Programatically click the link to trigger the download 
	a.click();

	//Release the reference to the file by revoking the Object URL 
	window.URL.revokeObjectURL(url); 
}