export function serverCalculation(players:number, mods:number, os:String) {
    let os_recommend: string = "Linux";
    let os_score: number = 1;
    let ram_needed: number;
    let ram_recommend: number = 3;
    let cpu_needed: number;
    let cpu_recommend: number = 2;
    let storage_needed: number;
    let storage_recommend:number = 1;
    let total: number;
    let grade: string;

    //Check if OS is Linux
    if (os.toLowerCase() === os_recommend.toLowerCase()) {
        os_score = 1;
    } else { //OS is Windows
        os_score = 1.2;
    }

    ram_needed = (ram_recommend+((ram_recommend*0.018*players)+(ram_recommend*0.035*mods))) * os_score;
    cpu_needed = (cpu_recommend+((cpu_recommend*0.005*players)+(cpu_recommend*0.015*mods))) * os_score;
    storage_needed = (storage_recommend+((storage_recommend*0.01*players)+(storage_recommend*0.02*mods))) * os_score;
    total = ram_needed+cpu_needed+storage_needed;

    //Check if total is over 100
    if (total > 100) {
        total = 100;
    }

    //Check if cpu_needed is over 5
    if (cpu_needed > 4.5) {
        cpu_needed = 4.5;
    }

    //Compare total to a list of scores
    grade = total <= 30 ? "Minimum" : total <= 40 ? "Vanilla" : total <= 50 ? "Vanilla+" : total <= 60 ? "Large Vanilla" : total <= 70 ? "XL Vanilla" : total <= 80 ? "Huge Vanilla" : total <= 90 ? "Extreme Vanilla" : "Insane Vanilla";

    //round up to nearest 0.1
    cpu_needed = Math.ceil(cpu_needed * 10) / 10;
    storage_needed = Math.ceil(storage_needed * 10) / 10;
    total = Math.ceil(total * 10) / 10;

    //round ram to group of 4 but for the first 2 it goes up by 1
    if (ram_needed <= 4) {
        ram_needed = Math.ceil(ram_needed);
    } else {
        ram_needed = Math.ceil(ram_needed / 2) * 2;
    }

    //Special case for Vanilla under 10 players
    if (players <= 10 && mods === 0) {
        ram_needed = 1;
    }

    return {ram_needed, cpu_needed, storage_needed, total, grade};
}