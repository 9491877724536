import React from 'react';
import '../css/App.css';
import { Link,useLocation } from 'react-router-dom';
import {
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Badge,
  Text,
  Link as ChakraLink,
  Button
} from '@chakra-ui/react'
import json from '../providers/providers.json';

export function Providers() {
  const location = useLocation();
  const { servers } = json;

  let server = location.state.type;
  let mods = location.state.mods;
  let ram_needed = location.state.ram;
  let players = location.state.players;
  let cpu = location.state.cpu;

  return (
    <div className="App">
        <header className="App-header">
          <Text fontSize="5xl">Server Hosting</Text>
          <Text fontSize="xs">These are Affiliate links</Text>
          <Text>Recommended amount of RAM is {ram_needed} GB</Text>
          <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(300px, 1fr))'>
            {servers.map((server) => {
              return (
                <Card>
                  <CardHeader>
                    <Heading size='md'>{server.provider}</Heading>
                  </CardHeader>
                  <CardBody>
                    {
                      //Put each tag in a badge
                      server.tags && server.tags.map((tag) => {
                        return (
                          <Badge colorScheme="green" fontSize={"md"} mr={1}>{tag}</Badge>
                        )
                      })
                    }
                  </CardBody>
                  <CardFooter>
                    <ChakraLink href={server.link} isExternal className='wide'><Button colorScheme="green">Order</Button>
                    </ChakraLink>
                  </CardFooter>
                </Card>
              )
            })
            }
          </SimpleGrid>
          <Link to ="/" state={{type: server, mods: mods, ram: ram_needed, players: players, cpu: cpu}} className='NoUnderline'><Button colorScheme="yellow">Go Back</Button></Link>
        </header>
    </div>
  );
}

export default Providers;