import '../css/App.css';
import React from 'react';
import { Link,useLocation } from 'react-router-dom';
import { downloadStart } from '../functions/download';
import { HStack, Divider, Card, 
  CardBody, CardHeader, Text, Button,
  Code, Center, Tooltip, CardFooter } from '@chakra-ui/react'
  


export function Tutorial() {
  const location = useLocation();

  const mods = location.state.mods;
  const type = location.state.type;
  const players = location.state.players;

  let ram = "This already has the dedicated RAM set from the previous page, which was " + location.state.ram + "GB";


  return (
    <div className="App">
        <header className="App-header">
            <Card backgroundColor="gray.200" className="fullscreen">
              <CardHeader>
                <Text fontSize="2xl">Server Tutorial for {location.state.type}</Text>
              </CardHeader>
              <CardBody>
                <Text fontSize="2xl">The Basics</Text>
                <Text fontSize="md">
                  1. Download the server files, usally googling "{location.state.type} server download" will do the trick.
                </Text>
                <Text fontSize="md" color="red">
                  Remember to rename the file to "server.jar" if it is not already.
                </Text>
                <Text fontSize="md">
                  2. Run the start.sh file with the server.jar in the folder you want the server in.<br/> If you are on windows, you have to download a program like Git Bash to run the start.sh.
                </Text>
                <Center height='50px'>
                  <Divider/>
                </Center>
                <Tooltip label={ram} placement="bottom">
                <Button size="lg" onClick={() => downloadStart(location.state.ram, location.state.type)} colorScheme="green">Download Start.sh</Button>
                </Tooltip>
                <Center height='50px'>
                  <Divider/>
                </Center>
                <Text fontSize="2xl">Port forwarding</Text>
                <Text fontSize="md">
                  1. Go to your router settings and find the port forwarding section. <br/> You can find the IP in the Terminal by typing and looking for the "inet" line.
                </Text>
                <Center>
                <HStack spacing={2}>
                  <Tooltip label="Windows" placement="bottom">
                  <Code>ipconfig</Code>
                  </Tooltip>
                  <Text fontSize="xs">or</Text>
                  <Tooltip label="Linux" placement="bottom">
                  <Code>ifconfig</Code>
                  </Tooltip>
                </HStack>
                </Center>
                <Text fontSize="md">
                  2. Forward the port you want to use for your server. (Default is 25565)
                </Text>
                <Text fontSize="md">
                  3. Go to your server settings, and set the port to the one you forwarded.
                </Text>
              </CardBody>
              <CardFooter width="100%">
                <Link to ="/" state={{type: type, players: players, mods: mods}} className='NoUnderline'><Button size="lg" colorScheme="yellow">Back</Button></Link>
              </CardFooter>
            </Card>
        </header>
    </div>
  );
}

export default Tutorial;