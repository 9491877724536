export function startMaker(ram: number, type: string){
    
        let ram_min: number = 2;
        let serverjar: string = "server.jar";
    
        // Check if ram is less than 4
        if (ram <= 4) {
            ram = 4;
        } 
    
        //Make the start.sh file
        let start = `#!/bin/bash  
# Minecraft Server Start Script for a ${type} Server by ServerSpec
#
# This script will start the Minecraft server with the correct amount of RAM.
# You can change the amount of RAM by editing the ram variable in the start.sh file.

# Set the amount of RAM to use
ram=${ram}

# Set the server jar file name
serverjar=${serverjar}

# Start the server
echo "Starting server..."
screen -dmS minecraft java -Xmx${ram}G -Xms${ram_min}G -jar $serverjar nogui

# Show the server console
echo "Showing server console..."
screen -r minecraft`;

        return start;
        
    }

