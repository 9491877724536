import React from 'react';
import './css/index.css';
import Index from './Pages/Main';
import Tutorial from './Pages/Tutorial';
import Providers from './Pages/providers';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

let App = () => {
    return (
    <Router>
        <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/providers" element={<Providers />} />
        </Routes>
    </Router>
    )
}

export default App;